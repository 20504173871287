<template>
  <v-container>
      <div class="my-10" style="text-align: center;">
          <h2>Escuela de Lectura Privacy Policy </h2>
      </div>
      <p dir="ltr" style="line-height:1.2;margin-top:12pt;margin-bottom:12pt;"><span style="font-size:10.5pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Last Updated: October 2021</span></p>
      <p class="title-privacy">1. Overview</p>
        <ul>
            <li>We are committed to protecting your privacy. We understand how important privacy is to you, and we are committed to protecting your privacy and to creating a safe and secure environment for learners of all ages.</li>
        </ul>
      <p class="title-privacy">2. Collection of information</p>
        <p></p>
        <ul>
            <li>We collect information from you directly, such as when you create an account, communicate with us or otherwise provide information during your use of the Service.</li>
        </ul>
        <ul>
        <li>Keep track of whether you are signed in or have previously signed in so that we can display all the features that are available to you.</li>
        </ul>
        <ul>
            <li>Remember your settings on the pages you visit, so that we can display your preferred content the next time you visit.</li>
        </ul>
        <p class="title-privacy">3. How we use your information</p>
    <ul>
        <li>Provide customer service
        </li>
        <li>Send you confirmations, updates, security alerts, and support and administrative messages
        </li>
        <li>Detect and prevent fraud</li>
        <li>Operate, protect, and optimize the service and your experience, such as by performing analytics and conducting product research</li>
        <li>Troubleshoot and protect against errors </li>
        <li>Personalize and improve the service</li>
        <li>Monitor and analyze usage and trends and otherwise measure the effectiveness of the service</li>
        <li>Develop new features</li>
    </ul>
    <p class="title-privacy">4. How protect your information</p>
    <ul>
        <li>We take measures designed to protect your information in an effort to prevent loss, misuse, unauthorized access, disclosure, alteration, and destruction. Please be aware, however, that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. To protect the confidentiality of information maintained in your account, you must keep your password confidential and not disclose it to any other person. You are responsible for all uses of the service by any person using your password. Please advise us immediately if you believe your password has been misused. If there is any disclosure or access to any student data by an unauthorized party, we will promptly notify the affected subscriber and will use reasonable efforts to cooperate with their investigation of the incident.</li>
    </ul>
    <p class="title-privacy">5. Your choices and opting out</p>
    <ul>
        <li>Student data is provided and controlled by subscriber. If you have any questions about reviewing, modifying, or deleting student data, please contact subscriber directly. Following termination or deactivation of your account, we may retain profile information and content for a commercially reasonable time for backup, archival, or audit purposes, but any and all student data associated with the account will be promptly deleted.</li>
    </ul>
    <p class="title-privacy">6. Changes and updates to the policy</p>
    <ul>
        <li>We reserve the right to change the provisions of this policy at any time, in which case we will notify you through the service and ask for your consent to any material changes. Please revisit this page periodically to stay aware of any changes as well. Your acceptance of the new policy and continued use of the service indicates that you have read, understood, and agreed to the updated version of this policy.</li>
    </ul>
    </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
    .terms{
        padding: 10px 0 15px 0 !important;
        display: block;
        font-size: 19px;
    }
    .title-privacy{
        font-weight: 800;
        padding-top: 5px;
    }

</style>